import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import {
    set_album, cart_fetch_finish,
    cart_report_setter,
    set_cart_credit,
    set_cart, set_cart_cost,
    set_cart_date,
    set_cart_time_index,
    set_order_address,
    set_order_shipping,
    set_shipping_price,
    cart_save_sanad,
    logout,
    offline_mode_cart,
    set_city_id
} from "../confg";

const cart_credit = createReducer(0, {
    [set_cart_credit]: (state, action) => {
        return action.payload;
    }
});

const cart = createReducer({ products: [], albums: [], virtual: false, virtual_product: [], virtual_album: [] }, {
    [set_album]: (state, action) => Object.assign({}, state, { albums: action.payload }),
    [set_cart]: (state, action) => Object.assign({}, state, { products: action.payload }),
    [logout]: (state, action) => ({ products: [], albums: [] })
});

export const cart_report = createReducer({ total_kaser: 0, total_make_pay: 0, total_price: 0, total_discount: 0, total_weight: 0 }, {
    [cart_report_setter]: (state, action) => {
        return action.payload;
    },

    [logout]: (state, action) => {
        return { total_kaser: 0, total_make_pay: 0, total_price: 0, total_discount: 0, total_weight: 0 };
    }
});

export const cart_costs = createReducer({ total_weight_to_pay: null, total_money_to_pay: null }, {
    [set_cart_cost]: (state, action) => {
        return action.payload;
    },

    [logout]: (state, action) => {
        return { total_weight_to_pay: null, total_money_to_pay: null }
    }
});

export const order_address = createReducer(null, {
    [set_order_address]: (state, action) => action.payload
});

export const order_city = createReducer(null, {
    [set_city_id]: (state, action) => action.payload
});

export const order_shipping = createReducer(null, {
    [set_order_shipping]: (state, action) => action.payload
});

export const price_shipping = createReducer(0, {
    [set_shipping_price]: (state, action) => action.payload
});

export const date = createReducer(null, {
    [set_cart_date]: (state, action) => action.payload
});

export const time_index = createReducer(null, {
    [set_cart_time_index]: (state, action) => action.payload
});

export const cart_update = createReducer(true, {
    [cart_fetch_finish]: (state, action) => action.payload
});

export const sanad = createReducer(0, {
    [cart_save_sanad]: (state, action) => action.payload
});

export const is_offline_mode = createReducer(true, {
    [offline_mode_cart]: (state, action) => action.payload
});

export default combineReducers({
    cart_credit, sanad, cart, cart_report, cart_costs, order_address, order_city, order_shipping, price_shipping, date, time_index, cart_update, is_offline_mode
})