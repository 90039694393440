import * as React from "react";
import {Spinner} from "react-bootstrap";

class Loader extends React.Component{
    render() {
        return <div className="position-fixed page-loader">
            <div className="position-absolute spinner-loader d-flex align-items-center justify-content-center">
                <p className="d-inline-flex p-0 m-0">{this.props.title}</p>
                <Spinner animation="border" className={"mx-2"}/>
            </div>
         </div>
    }
}
export default Loader
Loader.defaultProps={
    title:"منتظر بمانید"
};