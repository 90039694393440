import React from "react";
import * as axios from "axios";
import { withRouter } from 'react-router-dom';
import { Card, Container, Row } from "react-bootstrap";
import moment from 'moment-jalaali';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { conf } from "../confg";
import AppBar from "../blocks/AppBar";
import Footer from "../blocks/Footer";
import PhoneButton from '../blocks/PhoneButton';

class SingleNews extends React.Component {
    constructor(props) {
        super(props);

        this.state = { news: { news_description: [] } };
    }

    componentDidMount() {
        if (typeof this.props.location.search !== 'undefined' && this.props.location.search !== null) {
            let res = decodeURIComponent(this.props.location.search);

            if (res.length > 1) {
                try {
                    let params = res.split("&");
                    let news_id = params[0].split("=")[1];

                    axios({
                        "url": conf.server_url + "news/" + news_id,
                        "method": "GET",
                    }).then(res => {
                        if (res.status === 200) {
                            this.setState({ news: res.data })
                        }
                    })
                } catch (e) {
                    this.props.history.push("/404")
                }
            } else {
                this.props.history.push("/404")
            }
        }
    }

    render() {
        window.scrollTo(0, 0)

        let { news } = this.state;
        // let doc = news['news_description'].length > 0 ? new DOMParser().parseFromString((news['news_description'][0]['news_description']), "text/html").querySelector('body').innerHTML : '  ';
        let doc = news['news_description'].length > 0 ? new DOMParser().parseFromString((news['news_description'][0]['news_description']), "text/html").querySelector('body').innerHTML : '  ';
        // doc = doc.replace('<a href="\\\&\quot;', '<a target="_blank" rel="noopener noreferrer" href="');
        // doc = doc.substring(0, doc.length - 1);

        return (
            <div className={"wrapper"}>
                <AppBar />

                <PhoneButton />

                <div className={window.innerWidth >= 768 && "page-content"}>
                    <Container fluid={true}>
                        <Row className={"mt-5"}>
                            <Card className="col-12 rtl">
                                <div className="row">
                                    {news['news_image'] && <div className="col-sm-4">
                                        <img className="single-news-img" variant="top" src={conf.image_url_news + news['news_image']} alt="عکس خبر"/>
                                    </div>}

                                    <div className="col-sm-8">
                                        <Card.Body style={{ lineHeight: '1.7rem'}}>
                                            {news['news_last_modified'] !== null ? 
                                                <small className="text-muted badge-news ">{moment(news['news_last_modified']).format('jYYYY/jM/jD')}</small>
                                                :
                                                <small className="text-muted badge-news ">{moment(news['news_date_added']).format('jYYYY/jM/jD')}</small>
                                            }

                                            {news['news_description'].length > 0 && <>
                                                <Card.Title className="mt-4 single-news-title">{news['news_description'][0]['news_name']}</Card.Title>
                                                <Card.Text className="single-news">
                                                    {/* <div dangerouslySetInnerHTML={{ __html: doc }} /> */}

                                                    <CKEditor
                                                        disabled
                                                        editor={ ClassicEditor }
                                                        data={doc}
                                                        config={{
                                                            language: 'fa',
                                                            toolbar: [],
                                                        }}
                                                    />
                                                </Card.Text>
                                            </>}
                                        </Card.Body>
                                    </div>
                                </div>
                            </Card>
                        </Row>
                    </Container>
                </div>

                <Footer />
            </div>
        )
    }
}

export default withRouter(SingleNews);