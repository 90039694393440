import { createReducer } from '@reduxjs/toolkit';
import { set_pay_type } from '../confg';

const pay_type = createReducer('cashe', {
  [set_pay_type]: (state, action) => {
    return action.payload;
  },
});

export default pay_type;
