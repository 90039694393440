import * as React from "react";
import { Modal, Row, Col, Button, Image } from "react-bootstrap";

import logo from '../assets/images/logo_pwa.png';
import share from '../assets/images/share.svg';
import add from '../assets/images/add.svg';
import menu from '../assets/images/menu.svg';

class PWAModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: this.props,
        }
    }

    render() {
        const { showModal, onHide } = this.props;

        return (
            <Modal show={showModal} onHide={onHide} style={{ zIndex: '10000000000' }}>
                <Modal.Body className={"rtl"}>
                    <Row>
                        <Col xs={9} sm={9}>
                            <span style={{ color: '#073054', fontWeight: 'bold' }}>
                                نسخه اپ طلا و جواهرات رضوان را به صفحه اصلی اضافه کنید.
                            </span>
                        </Col>
                        <Col xs={3} sm={3} style={{ padding: '0', textAlign: 'center' }}>
                            <Image src={logo} style={{ width: '50px' }} />
                            <br />
                            <span style={{ fontSize: '0.7rem', fontWeight: 'bold', color: 'rgb(102,0,19)' }}>رضوان</span>
                        </Col>
                    </Row>
                    <div style={{ fontSize: '0.7rem', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'stretch', direction: 'rtl' }}>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right', color: '#073054', fontWeight: 'bold' }}>
                                <p>راهنما برای ios:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right' }}>
                                <p>۱. دکمه <strong>Share</strong> را در نوار پایین کلیک کنید.</p>
                            </Col>
                            <Col xs={2} style={{ textAlign: 'center' }}>
                                <Image src={share} style={{ width: '15px', textAlign: 'center' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right' }}>
                                <p>۲. گزینه <strong>Add to Home Screen</strong> را انتخاب کنید.</p>
                            </Col>
                            <Col xs={2} style={{ textAlign: 'center' }}>
                                <Image src={add} style={{ width: '17px', textAlign: 'center' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right' }}>
                                <p>۳. در قسمت بالا روی <strong>Add</strong> کلیک کنید.</p>
                            </Col>
                            <Col xs={2} style={{ textAlign: 'center' }}>
                                <p style={{ color: '#0775ee', textAlign: 'center' }}>Add</p>
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ height: '1px', color: 'grey', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                    <div style={{ fontSize: '0.7rem', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'stretch', direction: 'rtl' }}>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right', color: '#073054', fontWeight: 'bold' }}>
                                <p>راهنما برای android:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right' }}>
                                <p>۱. آیکون <strong>Menu</strong> را در نوار بالا کلیک کنید.</p>
                            </Col>
                            <Col xs={2} style={{ textAlign: 'center' }}>
                                <Image src={menu} style={{ width: '15px', textAlign: 'center' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right' }}>
                                <p>۲. گزینه <strong>Add to Home Screen</strong> را انتخاب کنید.</p>
                            </Col>
                            {/* <Col xs={2}>
                                 <p style={{color: '#0775ee', textAlign: 'left'}}>Add to Home Screen</p>
                             </Col> */}
                        </Row>
                        <Row>
                            <Col xs={10} style={{ textAlign: 'right' }}>
                                <p>۳. پس از بارگذاری لوگو، روی <strong>Add</strong> کلیک کنید.</p>
                            </Col>
                            <Col xs={2} style={{ textAlign: 'center' }}>
                                <p style={{ color: '#0775ee', textAlign: 'center' }}>Add</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-success" variant="secondary" onClick={onHide} style={{ border: '0px', width: '60%', color: '073054', margin: '0 auto' }}>
                        متوجه شدم
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default PWAModal;