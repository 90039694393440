import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { LocalizeProvider, localizeReducer } from 'react-localize-redux';
import thunk from 'redux-thunk';
import * as axios from 'axios';
import { Provider as ProviderKeepAlive } from 'react-keep-alive';

import App from './App';
import { conf } from './confg';
import './assets/css/index.css';
import 'video-react/dist/video-react.css';
import * as serviceWorker from './serviceWorker';

//import reducers
import user from './reducers/user';
import single from './reducers/singleProductActive';
import filters from './reducers/filters';
import viewModeReducer from './reducers/viewMode';
import gold_price from './reducers/goldPrice';
import request_error from './reducers/errorReducer';
import success_message from './reducers/successReducer';
import cart from './reducers/cart';
import subcategories from './reducers/subcategories';
import last_storePlace_url from './reducers/urlReducer';
import license from './reducers/license';
import brands from './reducers/brands';
import categories from './reducers/categories';
import HelmetProviders from './helmet/HelmetProvider/HelmetProviders';
import deposit_price from './reducers/deposit';
import pay_type from './reducers/payType';
import sharedStateReducer from './reducers/sharedState';

const rootReducer = combineReducers({
  // insurance,
  user,
  single,
  cart,
  last_storePlace_url,
  filters,
  gold_price,
  request_error,
  success_message,
  subcategories,
  viewModeReducer,
  localize: localizeReducer,
  license,
  brands,
  categories,
  deposit_price,
  pay_type,
  sharedState: sharedStateReducer
});
// const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const update_gold = () => {
  axios({
    url: conf.server_url + 'gold',
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }).then(e => {
    store.dispatch({ type: 'update_price', payload: Math.ceil(e.data / 10000) * 10000 });
  });
  // setTimeout(update_gold,120000)
};

update_gold();

// const evtSource = new EventSource('https://rezvanjewellery.com/.well-known/mercure?topic=gold');
// const evtSource = new EventSource(conf.mercury_url);

// evtSource.onmessage = function logEvents (event) {
//   store.dispatch({
//     type: 'update_price',
//     payload: Math.ceil(event.data / 10000) * 10000
//   });
// };

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <ProviderKeepAlive include='StorePlace'>
        <HelmetProviders>
          <App />
        </HelmetProviders>
      </ProviderKeepAlive>
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
