import React from 'react';
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export default class PhoneButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        }
    }

    render() {
        const { showModal } = this.state
        var today = new Date()
        const hour = today.getHours()

        return (
            <>
                {/*{hour > 9 && hour < 22 ? <a href="tel:02191312001">*/}
                {/*    <Button type='primary' className="phone-btn d-lg-none">*/}
                {/*        <FontAwesomeIcon icon={faPhone} style={{width: '100%', height: '100%'}}/>*/}
                {/*    </Button>*/}
                {/*</a>*/}
                {/*:*/}
                {/*<Button type='primary' className="phone-btn d-lg-none" onClick={() => {this.setState({showModal: true})}}>*/}
                {/*    <FontAwesomeIcon icon={faPhone} style={{width: '100%', height: '100%'}}/>*/}
                {/*</Button>*/}
                {/*}*/}

                <Modal show={showModal} onHide={() => this.setState({showModal: false})} style={{ zIndex: '10000000000' }}>
                    <Modal.Body className={"rtl"} style={{ fontSize: '.9rem', lineHeight: '1.7rem' }}>
                        <span style={{ fontWeight: 'bold' }}>مشتری گرامی،</span>
                        <br />
                        بین ساعات ۹ تا ۲۲ پاسخ‌گوی شما هستیم.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-success" variant="secondary" onClick={() => this.setState({showModal: false})} style={{ border: '0px', width: '60%', color: '073054', margin: '0 auto' }}>
                            متوجه شدم
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}