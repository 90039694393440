import React from "react";
import * as axios from "axios";
import Masonry from "react-masonry-css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import { conf } from "../confg";
import AppBar from "../blocks/AppBar";
import Footer from "../blocks/Footer";
import PhoneButton from '../blocks/PhoneButton';

class NewsAll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: []
        }
    }

    componentDidMount() {
        axios({
            "url": conf.server_url + "news",
            "method": "GET",
        }).then(res => {
            if (res.status === 200) {
                this.setState({ lists: res.data.data })
            }
        })
    }

    render() {
        window.scrollTo(0, 0)

        const breakpointColumnsObj = {
            default: 4,
            1100: 3,
            700: 2,
            500: 1
        };

        let { lists } = this.state,
            items = lists.map(function (item) {
                // let height = (Math.floor(Math.random() * 50) + 100);
                // let doc = new DOMParser().parseFromString((item['news_description'][0]['news_description']), "text/html").querySelector('body').firstChild.innerHTML;

                return <Card className={"news-box"} style={{ border: 'none' }}>
                    <Row style={{ width: '100%' }}>
                        <Col xs={4}>
                            <img style={{ width: '100%', margin: '.5rem', borderRadius: '.25rem' }} alt={'اخبار' + item.news_id} src={conf.image_url_news + item['news_image']}></img>
                        </Col>
                        <Col xs={8} style={{ padding: '.5rem' }}>
                            <span>{item['news_description'][0]['news_name']}</span>

                            <div style={{ alignSelf: 'flex-end' }}>
                                <Link push className={"link-news-all c-font-xs"} to={{ pathname: '/single_news', search: "?id=" + item['news_id'] }}>
                                    <div style={{ margin: '1rem 0 .5rem 0' }}>
                                        <Translate id={"see_more"} />
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: '5px' }} />
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    {/* <Card.Img variant="top" src="https://i.redd.it/j7k8y1qwq5701.jpg" /> */}
                    {/* {conf.image_url + item['news_image']} */}

                    {/* <Card.Title>{item['news_description'][0]['news_name']}</Card.Title>

                    <Card.Body className={"bg-white rtl"}>
                        {item['news_description'].length > 0 && <>
                            <Card.Text>
                                <div className="news-doc" dangerouslySetInnerHTML={{ __html: doc }} />....
                            </Card.Text>
                            <Link push className={"link-news-all"} to={{
                                pathname: '/single_news',
                                search: "?id=" + item['news_id'],
                            }}><Translate id={"see_more"} /></Link>
                        </>}
                    </Card.Body>

                    <Card.Footer className={"bg-dark-color main-color bold"}>
                        <small className="text-muted bold">{item['news_last_modified']}</small>
                    </Card.Footer> */}
                </Card>
            });

        return (
            <div className={"wrapper"}>
                <AppBar />

                <PhoneButton />

                <div className={window.innerWidth >= 768 && "page-content"}>
                    <Container fluid={true}>
                        <Row style={{marginTop: '1.5rem'}}>
                            <Col>
                                {/* <h3 className="rtl mb-4">اخبار طلا و جواهرات رضوان</h3> */}

                                <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid rtl" columnClassName="my-masonry-grid_column">
                                    {items}
                                </Masonry>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Footer />
            </div>
        )
    }
}

export default NewsAll;