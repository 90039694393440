import { createAction } from '@reduxjs/toolkit';
import * as axios from 'axios';
import {
  set_album,
  set_cart_cost,
  cart_report_setter,
  conf,
  set_cart,
  set_cart_date,
  set_cart_time_index,
  set_order_address,
  set_order_shipping,
  set_shipping_price,
  cart_fetch_finish,
  set_cart_credit,
  cart_save_sanad,
  product_list_localstorage,
  offline_mode_cart,
  album_list_localstorage,
  set_city_id
} from '../confg';

import { calculate_price_without_dis, hasDiscount, notNullAndUndefined } from '../helper';
import { successAction } from './successAction';
import { errorAction } from './request_error';
import { store } from '../index';

export const set_cart_products = createAction(set_cart);
export const set_cart_albums = createAction(set_album);
export const set_cart_report = createAction(cart_report_setter);
export const set_cart_sanad = createAction(cart_save_sanad);
export const cart_cost_setter = createAction(set_cart_cost);
export const order_address_setter = createAction(set_order_address);
export const order_city_setter = createAction(set_city_id);
export const order_shipping_setter = createAction(set_order_shipping);
export const finish_update_cart = createAction(cart_fetch_finish);

export const shipping_price_setter = price => {
  return dispatch => {
    dispatch({ type: set_shipping_price, payload: price });
    dispatch(calculate_cart_costs());
  };
};

export const mandeHesab_setter = () => {
  return dispatch => {
    axios({
      url: conf.server_url + 'mandeHesab',
      method: 'GET',
      timeout: 0,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(e => {
        dispatch(credit_setter(e.data.pool));
      })
      .catch(e => {
        console.log(JSON.stringify(e));
      });
  };
};

export const credit_setter = createAction(set_cart_credit);
export const cart_date = createAction(set_cart_date);
export const cart_time_index = createAction(set_cart_time_index);

export const offline_mode = mode => {
  return (dispatch, getState) => {
    let product_storage = localStorage.getItem(product_list_localstorage);
    let form = [];

    if (!mode && notNullAndUndefined(product_storage) && product_storage !== '') {
      let products_list = JSON.parse(product_storage);
      products_list.map(obj => {
        let object = obj['product'];

        if (typeof object['products'] !== 'undefined' && Array.isArray(object['products'])) {
          obj['product']['products'].map(prod => {
            if (prod['barcode'] !== obj['barcode']) {
              return 0;
            } else {
              // if (notNullAndUndefined(localStorage.getItem('token') && Object.keys(user).length>0 && user['is_teammate']==1)){
              // axios({
              //     "url": conf.server_url+"test",
              //     "method": "POST",
              //     "timeout": 0,
              //     "headers": {
              //         Accept: "application/json",
              //         Authorization: `Bearer ${localStorage.getItem('token')}`,
              //         "Content-Type": "application/x-www-form-urlencoded"
              //     },
              //     "data": {
              //         "id":prod["id"]
              //     }
              // }).then(res=>{
              //     let price=res.data;
              //     dispatch(add_basket(id,price));
              // })

              // }else{
              // id=prod['id'];
              // dispatch(add_basket(id,obj.price));
              // }
              form.push(prod['id']);
            }

            return null;
          });
        } else {
          form.push(object['id']);
        }

        return null;
      });
    }

    if (product_storage != null) {
      dispatch(add_basket_in_bulk(form));
    }

    dispatch({ type: offline_mode_cart, payload: mode });
  };
};

export const add_basket_in_bulk = array => {
  var json_arr = JSON.stringify(array);
  let form = new FormData();
  form.append('products_array', json_arr);

  return dispatch => {
    if (notNullAndUndefined(localStorage.getItem('token'))) {
      dispatch(finish_update_cart(false));
      axios({
        url: conf.server_url + 'customers_basket/bulk',
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: form
      })
        .then(e => {
          dispatch(getBasket());
          localStorage.removeItem('product_list');
          dispatch(successAction('محصول مورد نظر به سبد خرید اضافه شد'));
          dispatch(finish_update_cart(true));
        })
        .catch(e => {
          dispatch(finish_update_cart(true));
          dispatch(errorAction(e.response.data.msg));
          localStorage.removeItem(product_list_localstorage);
          localStorage.removeItem(album_list_localstorage);
          if (e.response.status === 401) {
            window.location.href = '/login';
          }
        });
    }
  };
};

export const add_basket = (id, price) => {
  let form = new FormData();
  form.append('products_id', id);
  form.append('final_price', price);

  return dispatch => {
    if (notNullAndUndefined(localStorage.getItem('token'))) {
      dispatch(finish_update_cart(false));
      axios({
        url: conf.server_url + 'customers_basket',
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: form
      })
        .then(e => {
          dispatch(getBasket());
          dispatch(successAction('محصول مورد نظر به سبد خرید اضافه شد'));
          dispatch(finish_update_cart(true));
        })
        .catch(e => {
          dispatch(finish_update_cart(true));
          dispatch(errorAction(e.response.data.msg));
          localStorage.removeItem(product_list_localstorage);
          localStorage.removeItem(album_list_localstorage);
          if (e.response.status === 401) {
            window.location.href = '/login';
          }
        });
    }
  };
};

export const add_basket_bulk = price_array => {
  var json_arr = JSON.stringify(price_array);
  let form = new FormData();
  form.append('products_array', json_arr);

  return dispatch => {
    if (notNullAndUndefined(localStorage.getItem('token'))) {
      dispatch(finish_update_cart(false));
      axios({
        url: conf.server_url + 'customers_basket/bulk',
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: form
      })
        .then(e => {
          dispatch(getBasket());
          dispatch(successAction('محصول مورد نظر به سبد خرید اضافه شد'));
          dispatch(finish_update_cart(true));
        })
        .catch(e => {
          dispatch(finish_update_cart(true));
          dispatch(errorAction(e.response.data.msg));
          localStorage.removeItem(product_list_localstorage);
          localStorage.removeItem(album_list_localstorage);
          if (e.response.status === 401) {
            window.location.href = '/login';
          }
        });
    }
  };
};

export const add_basket_offline = (product, price, albm, barcode) => {
  return (dispatch, getState) => {
    dispatch(finish_update_cart(false));
    let productsStorage = localStorage.getItem(product_list_localstorage);
    let albums = localStorage.getItem(album_list_localstorage);

    if (!notNullAndUndefined(productsStorage)) {
      localStorage.setItem(product_list_localstorage, JSON.stringify([{ product: product, price: price, barcode: barcode }]));
    } else {
      let products = JSON.parse(productsStorage);
      products.push({ product: product, price: price, barcode: barcode });
      products = [...new Set(products.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
      localStorage.setItem(product_list_localstorage, JSON.stringify(products));
    }

    //albums set
    if (!notNullAndUndefined(albums)) {
      albums = [];
      albums.push(albm);
      localStorage.setItem(album_list_localstorage, JSON.stringify(albums));
    } else {
      albums = JSON.parse(albums);
      albums.push(albm);
      albums = [...new Set(albums)];
      localStorage.setItem(album_list_localstorage, JSON.stringify(albums));
    }

    dispatch(successAction('محصول مورد نظر به سبد خرید اضافه شد'));
    dispatch(set_cart_albums(albums));
    dispatch(finish_update_cart(true));
  };
};

export const getBasket = () => {
  return dispatch => {
    dispatch(finish_update_cart(false));

    if (notNullAndUndefined(localStorage.getItem('token'))) {
      axios({
        url: conf.server_url + 'customers_basket',
        method: 'get',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(res => {
          let album_ids = res.data.map(basket => basket['products']['album_id']);
          album_ids = [...new Set(album_ids)];
          dispatch(set_cart_products(res.data));
          dispatch(set_cart_albums(album_ids));
          dispatch(calculate_cart_totals(res.data));
          dispatch(finish_update_cart(true));
        })
        .catch(e => {
          dispatch(finish_update_cart(true));
        });
    } else {
      dispatch(finish_update_cart(true));
    }
  };
};

export const remove_product_basket = id => {
  return (dispatch, getState) => {
    let user = getState().user;
    if (notNullAndUndefined(localStorage.getItem('token')) && Object.keys(user).length > 0) {
      dispatch(finish_update_cart(false));
      axios({
        url: conf.server_url + 'customers_basket/' + id,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        timeout: 0
      })
        .then(e => {
          let album_ids = e.data.map(basket => basket['products']['album_id']);
          album_ids = [...new Set(album_ids)];
          dispatch(set_cart_albums(album_ids));
          dispatch(set_cart_products(e.data));
          dispatch(calculate_cart_totals(e.data));
          dispatch(successAction('محصول مورد نظر از سبد خرید شما حذف شد'));
          dispatch(finish_update_cart(true));
        })
        .catch(e => {
          dispatch(errorAction('در حذف از سبد خرید مشکلی پیش امده است'));
        });
    } else {
      dispatch(finish_update_cart(false));
      let products = localStorage.getItem(product_list_localstorage);
      let albums = localStorage.getItem(album_list_localstorage);

      if (notNullAndUndefined(products)) {
        products = JSON.parse(products);
        albums = JSON.parse(albums);
        let products_new = [];

        products.map(obj => {
          if (obj.product.id !== id) {
            products_new.push(obj);
          }

          return null;
        });

        if (notNullAndUndefined(albums)) {
          albums.map((id_album, index) => (id_album === id ? albums.splice(index, 1) : ''));
        }

        localStorage.setItem(product_list_localstorage, JSON.stringify(products_new));
        localStorage.setItem(album_list_localstorage, JSON.stringify(albums));
        dispatch(finish_update_cart(true));
      }
    }
  };
};

export const calculate_cart_totals = products => {
  return (dispatch, getState) => {
    let user = getState().user;
    let discount_total = 0;
    let ojrat_total = 0;
    let kasr_total = 0;
    let total_price = 0;
    let total_weight = 0;

    products.map((product, i) => {
      let has_discount = hasDiscount(product['discount']);

      if (Object.entries(user).length !== 0 && user['is_teammate']) {
        let $kasr = (product['formula']['k'] * product['products']['accounting_weight']) / 100;
        let $kasr_with_discount = has_discount ? ((product['formula']['k'] - product['discount']['percent']) * product['products']['accounting_weight']) / 100 : 0;
        let $ojrat = product['formula']['o'] * product['products']['accounting_weight'];
        let discount = has_discount ? $kasr - $kasr_with_discount : 0;
        total_weight = total_weight + product['products']['accounting_weight'];
        discount_total = discount_total + discount;
        ojrat_total = ojrat_total + $ojrat;
        kasr_total = kasr_total + $kasr;
        let price = product['final_price'] / 10;
        total_price = total_price + price;
      } else if (Object.entries(user).length !== 0 && !user['is_teammate']) {
        const state = store.getState();
        let p = state.gold_price;
        let price = product['final_price'];
        let discount = has_discount ? calculate_price_without_dis(p, product['formula'], product['products']['accounting_weight'], 0) - price : 0;
        total_price = parseFloat(total_price) + parseFloat(price) / 10;
        discount_total = discount_total + discount;
      }

      return null;
    });

    dispatch(
      set_cart_report({
        total_kaser: parseFloat(kasr_total).toFixed(3),
        total_make_pay: parseFloat(ojrat_total).toFixed(2),
        total_gr: (parseFloat(total_weight) + parseFloat(kasr_total)).toFixed(3),
        total_price: parseFloat(total_price).toFixed(0),
        total_discount: parseFloat(discount_total).toFixed(2),
        total_weight: parseFloat(total_weight).toFixed(3)
      })
    );

    dispatch(calculate_cart_costs());
  };
};

/*this user for final calculation*/
export const calculate_cart_costs = () => {
  return (dispatch, state) => {
    let total_weight_to_pay = null;
    let total_money_to_pay = null;
    let usr = state().user;
    let cart_report = state().cart.cart_report;
    let shipping_price = state().cart.price_shipping;

    if (usr['is_teammate']) {
      total_weight_to_pay = parseFloat(cart_report['total_kaser']) + parseFloat(cart_report['total_make_pay']);
      total_money_to_pay = parseFloat(cart_report['total_make_pay']);
    } else {
      total_money_to_pay = parseFloat(cart_report['total_price']) + parseFloat(shipping_price);
    }

    dispatch(cart_cost_setter({ total_weight_to_pay: parseFloat(total_weight_to_pay).toFixed(2), total_money_to_pay: parseFloat(total_money_to_pay).toFixed(2) }));
  };
};

export const set_all_default = () => {
  return (dispatch, state) => {
    dispatch(cart_date(null));
    dispatch(cart_time_index(null));
    dispatch(order_shipping_setter(null));
    dispatch(order_address_setter(null));
    dispatch(order_city_setter(null));
  };
};
