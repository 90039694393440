import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
  brand_filter,
  category_filter,
  color_filter,
  exist_filter,
  makep_filter,
  price_filter,
  select_filter,
  text_filter,
  weight_filter,
  sort_filter,
  def_make_filter,
} from '../confg';
import querystring from 'querystring';

const queryParam = querystring.parse(querystring.parse(window.location.search.slice(1))?.query);

const price = createReducer(queryParam?.price || null, {
  [price_filter]: (state, action) => {
    return action.payload;
  },
});

const weight = createReducer(queryParam?.weight || null, {
  [weight_filter]: (state, action) => {
    return action.payload;
  },
});

const text = createReducer(queryParam?.text || null, {
  [text_filter]: (state, action) => {
    return action.payload;
  },
});

const exist = createReducer(queryParam?.exist || '', {
  [exist_filter]: (state, action) => {
    return action.payload;
  },
});

const color = createReducer(queryParam?.color || '', {
  [color_filter]: (state, action) => {
    return action.payload;
  },
});

const category = createReducer(queryParam?.category || '', {
  [category_filter]: (state, action) => {
    return action.payload;
  },
});

const brand = createReducer(queryParam?.brand || '', {
  [brand_filter]: (state, action) => {
    return action.payload;
  },
});

const make_percent = createReducer(queryParam?.make_percent || null, {
  [makep_filter]: (state, action) => {
    return action.payload;
  },
});

const def_make = createReducer(queryParam?.def_make || null, {
  [def_make_filter]: (state, action) => {
    return action.payload;
  },
});

const select = createReducer(queryParam?.select || '', {
  [select_filter]: (state, action) => {
    return action.payload;
  },
});

const sort_by = createReducer(queryParam?.sort_by || '', {
  [sort_filter]: (state, action) => {
    return action.payload;
  },
});

export default combineReducers({ price, weight, text, exist, color, category, brand, make_percent, select, def_make, sort_by });
