import React from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { notNullAndUndefined } from '../helper'
import Footer from "../blocks/Footer";
import AppBar from "../blocks/AppBar";
import PhoneButton from '../blocks/PhoneButton';

class BrandPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exists_alphabet: [],
            brand_sort_alphabet: []
        };
        this.splitter = this.splitter.bind(this)
    }

    componentDidMount() {
        //console.log('did mount brands');
        // axios({
        //     "url": conf.server_url+"brands",
        //     "method": "GET",
        //     "timeout": 0,
        // }).then(response=>{
        //     let brands=response.data;
        //     brands.sort((a,b)=> b.brand< a.brand? 1:-1);
        //     //console.log(brands,'sorted');
        //     let sorted=this.splitter(brands,'brand');
        //     let exists_alphabet=Object.keys(sorted);
        //     let brand_sort_alphabet=Object.values(sorted);

        //     this.setState({brand_sort_alphabet,exists_alphabet})
        // })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.brands !== this.props.brands) {
            const { brands } = this.props;

            // brands.sort((a,b)=> b.brand< a.brand? 1:-1);
            let sorted = this.splitter(brands, 'brand');
            let exists_alphabet = Object.keys(sorted);
            let brand_sort_alphabet = Object.values(sorted);
            this.setState({ brand_sort_alphabet, exists_alphabet })
        }
    }

    splitter(array, field) {
        if (array.length === 0) {
            return {};
        }

        let sorted = (array.reduce((result, current_obj, index, src_arr) => {
            let firstLetter = notNullAndUndefined(current_obj[field]) ? current_obj[field][0].toLocaleUpperCase() : '-';
            if (!result[firstLetter]) {
                result[firstLetter] = { title: firstLetter, data: [current_obj] };
            } else {
                result[firstLetter].data.push(current_obj)
            }
            return result; //e.g next result is {t:{title:t,data:[{name:'tree',id:'2'}]}}
        }, {}));

        return sorted;
    }

    scrollIntoLetter(ref) {
        window.scrollTo(0, ref.current.offsetTop)
    }

    render() {
        let { exists_alphabet, brand_sort_alphabet } = this.state;

        exists_alphabet.map(letter => this["ref_" + letter] = React.createRef());

        return (
            <div className={"wrapper"}>
                <AppBar />

                <PhoneButton />

                <div className={window.innerWidth >= 768 && "page-content"}>
                    <Container fluid={true}>
                        <Row>
                            <Col lg={12} className={"dir-rtl"}>
                                <div className="d-flex flex-wrap">
                                    {exists_alphabet.map(letter => <span className="color-black bold px-4 btn-link cursor-grab letter-brands-top letter-brands" onClick={this.scrollIntoLetter.bind(this, this["ref_" + letter])}>{letter}</span>)}
                                </div>
                                <hr />
                                <div className="d-flex flex-row flex-wrap">
                                    {brand_sort_alphabet.map(obj => {
                                        return <div className={"d-flex flex-column flex-sm-row mx-3 mb-4 w-100 border-bottom"} ref={this["ref_" + obj["title"]]}>
                                            <span className="letter-brands ">{obj["title"]}</span>
                                            <div className={" d-flex flex-wrap"}>
                                                {obj.data.map(brand_obj => <Link to={{ pathname: "/store", state: { filter: { type: 'brand', val: brand_obj.brand } } }} className={"c-brand p-3 c-font-sm flex-inline"}>{brand_obj.brand}</Link>)}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Footer />
            </div>
        )
    }
}

const MapStateToProps = (state) => ({
    brands: state.brands
});

export default (connect(MapStateToProps)(BrandPage));