import { set_brands } from "../confg";
import { createAction } from "@reduxjs/toolkit";
import { conf } from "../confg";
import * as axios from "axios";

export const brands = createAction(set_brands);

export const set_all_brands = () => {
    return (dispatch) => {
        axios({
            "url": conf.server_url + "brands",
            "method": "GET",
        }).then(e => {
            dispatch(brands(e.data));
        })
    }
};
