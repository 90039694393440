import { createReducer } from '@reduxjs/toolkit'
import { set_license } from "../confg";

let has_license = null;
const license = createReducer(has_license, {
    [set_license]: (state, action) => {
        return action.payload
    }
});

export default license;