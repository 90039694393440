import {views_pr_actions} from "../confg";

export default  function viewModeReducer(state={view:'default'}, action) {
    switch (action.type) {
        // ...
        case views_pr_actions.update:
            return { ...state, view: action.payload };
        default:
            return state
    }
}