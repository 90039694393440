import moment from 'moment';

export function notNullAndUndefined(value) {
    return typeof value !== 'undefined' && value !== null && value !== '';
}

export const parseFloatNull = function (x) {
    let res = (x === null) ? 0 : parseFloat(x);
    return res;
}

export const validatePersian = function (value) {
    // var p = /^\s*([\u0600-\u06FF\s]*\-*)*\s*$/;
    var p = /^[\u0600-\u06FF\s\-]+$/;

    if (!p.test(value)) {
        return false
    }

    return true;
}

export function persianConvert(s) {
    return s.toString().replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
}

export function getAllUrlParams(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
}

export const calculate_price = (gold, formoula, w, discount = 0) => {
    let p = gold;
    let { o, k, t, b, r } = formoula;

    o = o !== null ? parseFloat(o) : 0;
    k = k !== null ? parseFloat(k) : 0;
    t = t !== null ? parseFloat(t) : 0;
    b = b !== null ? parseFloat(b) : 0;
    r = r !== null ? parseFloat(r) : 0;

    if (hasDiscount(discount)) {
        let dis = k - discount['percent'];
        if (k > 0) {
            k = dis;
        }
    }

    let price = (((w * p * ((k / 100) + 1) * b) + (w * o) + r) * t);

    return Math.ceil(price / 10000) * 10000
};

export const calculate_price_without_dis = (gold, formoula, w, discount) => {
    let { o, k, t, b, r } = formoula;

    o = o !== null ? parseFloat(o) : 0;
    k = k !== null ? parseFloat(k) : 0;
    t = t !== null ? parseFloat(t) : 0;
    b = b !== null ? parseFloat(b) : 0;
    r = r !== null ? parseFloat(r) : 0;

    let p = gold;
    let price = (((w * p * ((k / 100) + 1) * b) + (w * o) + r) * t);

    return (Math.ceil(price / 10000) * 10000)
};

/**
 *
 * @param obj discount
 * @returns {boolean}
 */
export const hasDiscount = function (obj) {
    return typeof obj !== "undefined" && obj.sale_type !== 'normal' && typeof obj.percent !== 'undefined' && obj.percent !== "" && obj.percent > 0 && moment(obj.start_date).isBefore(moment()) && moment(obj.expire_date).isAfter(moment())
};

export const unique = (a, field = null) => {
    if (field === null)
        return a.filter(function (item, i, ar) { return ar.indexOf(item) === i; })
    else {
        let flags = [], output = [], l = a.length, i;

        for (i = 0; i < l; i++) {
            if (flags[a[i][field]]) continue;
            flags[a[i][field]] = true;
            output.push(a[i]);
        }

        return output
    }
}