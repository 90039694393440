import { createReducer } from '@reduxjs/toolkit'
import { new_success } from "../confg";

const success_message = createReducer({ msg: null }, {
    [new_success]: (state, action) => {
        let x = { msg: action.payload }

        return x;
    }
});

export default success_message;