import * as axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { conf, set_uer, logout, set_deposit_price } from '../confg';

export const set_user = createAction(set_uer);
export const deposit_price_action = createAction(set_deposit_price);

export const logout_user = payload => {
  return dispatch => {
    localStorage.clear();
    dispatch({ type: logout, payload: payload });
  };
};

export const set_me = () => {
  return dispatch => {
    const form = new FormData();

    axios({
      url: conf.server_url + 'auth/me',
      method: 'POST',
      data: form,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(e => {
        dispatch(set_user(e.data));
      })
      .catch(e => {});
  };
};

export const getDeposit = () => {
  return dispatch => {
    const form = new FormData();

    axios({
      url: conf.server_url + 'deposit/calculate',
      method: 'POST',
      data: form,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(e => {
        dispatch(deposit_price_action(e?.data?.price ? e?.data?.price / 10 : 0));
      })
      .catch(e => {});
  };
};
