import { set_categories } from "../confg";
import { createAction } from "@reduxjs/toolkit";
import { conf } from "../confg";
import * as axios from "axios";

export const categories = createAction(set_categories);

export const set_all_categories = () => {
    return (dispatch) => {
        axios({
            "url": conf.server_url + "categories",
            "method": "GET",
        }).then(e => {
            dispatch(categories(e.data));
        })
    }
};
