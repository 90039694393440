// reducers/sharedState.js
const initialState = {
  value: 'default value' // Example initial state
};

const sharedStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SHARED_STATE':
      return {
        ...state,
        value: action.payload
      };
    default:
      return state;
  }
};

export default sharedStateReducer;
