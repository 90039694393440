export const user_actions = {
  update: 'UPDATE_USER',
  login: 'LOGIN_USER'
};
export const views_pr_actions = {
  update: 'UPDATE_VIEW'
};
export const addressActions = {
  address_update_form_val: 'FORMS_VAL'
};
export const set_product = 'SET_PRODUCT';
export const add_to_basket = 'ADD_TO_BASKET';
export const set_cart = 'SET_BASKET';
export const set_album = 'SET_ALBUM';
export const remove_from_cart = 'REMOVE_FROM_CART';
export const cart_report_setter = 'SET_CART_REPORT';
export const set_cart_cost = 'SET_CART_COST';
export const new_error = 'NEW_ERROR';
export const new_success = 'NEW_SUCCESS';
export const set_uer = 'SET_USER';
export const logout = 'LOGOUT';
export const set_order_address = 'SET_ORDER_ADDRESS';
export const set_city_id = 'SET_CITY_ID';
export const set_order_shipping = 'SET_ORDER_SHIPPING';
export const set_shipping_price = 'SET_SHIPPING_PRICE';
export const cart_save_sanad = 'SABT_SHOMARE_SANAD';
export const adding_cart = 'ADDING_TO_BASKET';
export const set_cart_default = 'SET_ALL_DEFAULT';
export const set_cart_time_index = 'SET_TIME_INDEX';
export const set_cart_date = 'SET_CART_DATE';
export const cart_fetch_finish = 'CART_FETCH_FINISH';
export const set_cart_credit = 'SET_CART_CREDIT';
export const price_filter = 'PRICE_FILTER';
export const weight_filter = 'WEIGHT_FILTER';
export const text_filter = 'TEXT_FILTER';
export const exist_filter = 'EXIST_FILTER';
export const brand_filter = 'BRAND_FILTER';
export const sort_filter = 'SORT_FILTER';
export const makep_filter = 'MAKE_PERCENT_FILTER';
export const def_make_filter = 'DEF_MAKE_FILTER';
export const select_filter = 'SELECT_FILTER';
export const color_filter = 'COLOR_FILTER';
export const category_filter = 'CATEGORY_FILTER';
export const set_filter_null = 'SET_FILTERS_NULL';
export const set_url_store = 'SET_URL_STORE';
export const set_sub = 'SET_SUB_CAT';
export const set_license = 'SET_LICENSE';
export const offline_mode_cart = 'OFFLINE_MODE_CART';
export const set_brands = 'SET_BRANDS';
export const set_categories = 'SET_CATEGORIES';
export const set_deposit_price = 'SET_DEPOSIT_PRICE';
export const set_pay_type = 'SET_PAY_TYPE';

export const peyk_id = 1;
export const post_id = 2;

export const product_list_localstorage = 'product_list';
export const album_list_localstorage = 'albums';

export const dates = ['9:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00'];

export const conf = {
  url: process.env.REACT_APP_URL,
  server_url: process.env.REACT_APP_SERVER_URL,
  image_url: process.env.REACT_APP_IMAGE_URL,
  product_image_url: process.env.REACT_APP_IMAGE_URL,
  mercury_url: process.env.REACT_APP_MERCURY_URL,
  image_url_news: 'https://rezvanjewellery.com/',
  base_url: process.env.REACT_APP_BASE_URL
};

// REACT_APP_BASE_URL = https://rezvanjewellery.com/
// REACT_APP_URL = /api/
// REACT_APP_SERVER_URL = /api/
// REACT_APP_IMAGE_URL =https://rezvanjewellery.com/
// REACT_APP_PRODUCT_IMAGE_URL =https://rezvanjewellery.com/api/
// REACT_APP_MERCURY_URL = https://rezvanjewellery.com/.well-known/mercure?topic=gold
