import { createReducer } from '@reduxjs/toolkit';
import { set_deposit_price } from '../confg';

const deposit_price = createReducer(0, {
  [set_deposit_price]: (state, action) => {
    return action.payload;
  },
});

export default deposit_price;
