import { set_categories } from "../confg";
import { createReducer } from '@reduxjs/toolkit';

const categories = createReducer([], {
    [set_categories]: (state, action) => {
        return action.payload
    },
});

export default categories
