export const convertPersianNumberToEnglish = (s = '', number = false) => {
  const elem = s
    .toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '');
  if (number) return +elem;
  return elem;
};

export const convertTimeStampToCountDown = (endTimeStamp = 0, startTimeStamp, callBack) => {
  const countDownDate = new Date(endTimeStamp).getTime();

  const interval = setInterval(function () {
    const now = new Date().getTime();

    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      clearInterval(interval);
      return callBack?.('منقضی شده', 0);
    }

    const range = endTimeStamp * 1000 - (startTimeStamp || 0) * 1000;
    const diff = Math.max(0, endTimeStamp * 1000 - new Date().getTime());
    const percentage = (100 * diff) / range;

    // console.log('distance', distance);

    callBack((days ? days + 'روز ' : '') + (hours || days ? hours + 'ساعت ' : '') + (minutes || hours ? minutes + 'دقیقه ' : '') + seconds + 'ثانیه ', percentage);
    // callBack((days ? days + ' : ' : '') + (hours || days ? hours + ' : ' : '') + (minutes || hours ? minutes + ' : ' : '00 : ') + seconds, percentage);
    // callBack?.(days ? days + ' days' : hours || minutes ? ' 1 day' : '', percentage);
  }, 1000);
};

// separator

export const separator = (input, seperater = ',', seperateLength = 3) => {
  if (input === null || input === undefined) return '';
  input = input.toString();
  let result = '';
  let count = 0;
  for (let i = input.length - 1; i > -1; i--) {
    if (count === seperateLength) {
      result = seperater + result;
      count = 0;
    }
    result = input.charAt(i) + result;
    count++;
  }
  return result;
};

  export const extractNumberFromString = (s = '') => {
    const elem = s
      .toString()
      .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
      .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '')
      .replace(/\D/g, '');
    return elem;
  };
