import { createReducer } from '@reduxjs/toolkit'
import { new_error } from "../confg";

const request_error = createReducer({ er: null }, {
    [new_error]: (state, action) => {
        let new_s = {};
        new_s['er'] = action.payload;

        return new_s
    }
});

export default request_error;