import { createReducer } from '@reduxjs/toolkit';
import { logout, set_uer } from '../confg';

const user = createReducer(
  {},
  {
    [set_uer]: (state, action) => {
      return action.payload;
    },

    [logout]: (state, action) => {
      return {};
    },
  },
);

export default user;
